
import { QueryUtils } from "./../../../utils/index";
import { DELETE, GET, POST, PUT  } from "../../../api/method";
import { IPaging } from "./type";
import axios from "axios";
export const orders = async (params : IPaging) => {
  // this code is test for local need to run backend service
  // const response = await axios.get(`http://localhost:1083/order-queries/orders/get-all${QueryUtils.query(params)}`, {
  //   headers: {
  //     'authorization': `eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcmVzSW4iOjE3NDA4NDU4NzIsImRhdGEiOnsiaWQiOiJiMzQxNWI1Yy00NTNjLTQ4ZTYtODk3YS01MGQ1Y2VmMWI1YzgiLCJlbWFpbCI6ImhpcHNpZ2FAZ21haWwuY29tIiwicHJvZmlsZSI6eyJuaWNrbmFtZSI6ImhpcHNpZ2EiLCJzbHVnIjoiaGlwc2lnYSJ9LCJjb250YWN0Ijp7ImNvbnRhY3RJZCI6ImIzNDE1YjVjLTQ1M2MtNDhlNi04OTdhLTUwZDVjZWYxYjVjOCIsImZpcnN0TmFtZSI6IsSQ4bupYyIsImxhc3ROYW1lIjoiVHLhu41uZyIsInBob25lcyI6W10sImVtYWlscyI6W10sImFkZHJlc3NlcyI6W10sImN1c3RvbUZpZWxkcyI6e319LCJyb2xlcyI6WyJDUyJdLCJwbGFucyI6W119LCJpYXQiOjE3NDAyNDEwNzJ9.jOR_fjOnJHiP5THzVYx5q40hX70UGENhoe7lwgkVi5HkgPtpF5UnUfwEvyim5jwyS1Njy4FD8xsM2xZgYKSBRYOtT1kQehWDwlHK69-zUjZFJtm8nvGJatPRR8qNdMolw3U5diQ2s1I917ci05teiZ_3wv9PWGwvgVSn_BYz8mrYVen8CZzv4uvKTgMwWUYUT9R6h0UgBaRejp1hq29N1sLWNcFNRsnE9Bk4jaU6u4BMLTbn2VKbxJ4iOLWEyjEzwJFMNbX1Nrv6WhJmK7NEoEm79HBJiYHmhCPlaG98P6iMYPQAofshUIY5gQC1ZRYKnm0Yl1qwwcbPkyCBx38upw`
  //   }
  // });
  // return response.data;
  return GET(`order/order-queries/orders/get-all${QueryUtils.query(params)}`);
};

export const detail = async (id : string) => {
  // const response = await axios.get(`http://localhost:1083/order-queries/orders/${id}`, {
  //   headers: {
  //     'authorization': `eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcmVzSW4iOjE3NDA4NDU4NzIsImRhdGEiOnsiaWQiOiJiMzQxNWI1Yy00NTNjLTQ4ZTYtODk3YS01MGQ1Y2VmMWI1YzgiLCJlbWFpbCI6ImhpcHNpZ2FAZ21haWwuY29tIiwicHJvZmlsZSI6eyJuaWNrbmFtZSI6ImhpcHNpZ2EiLCJzbHVnIjoiaGlwc2lnYSJ9LCJjb250YWN0Ijp7ImNvbnRhY3RJZCI6ImIzNDE1YjVjLTQ1M2MtNDhlNi04OTdhLTUwZDVjZWYxYjVjOCIsImZpcnN0TmFtZSI6IsSQ4bupYyIsImxhc3ROYW1lIjoiVHLhu41uZyIsInBob25lcyI6W10sImVtYWlscyI6W10sImFkZHJlc3NlcyI6W10sImN1c3RvbUZpZWxkcyI6e319LCJyb2xlcyI6WyJDUyJdLCJwbGFucyI6W119LCJpYXQiOjE3NDAyNDEwNzJ9.jOR_fjOnJHiP5THzVYx5q40hX70UGENhoe7lwgkVi5HkgPtpF5UnUfwEvyim5jwyS1Njy4FD8xsM2xZgYKSBRYOtT1kQehWDwlHK69-zUjZFJtm8nvGJatPRR8qNdMolw3U5diQ2s1I917ci05teiZ_3wv9PWGwvgVSn_BYz8mrYVen8CZzv4uvKTgMwWUYUT9R6h0UgBaRejp1hq29N1sLWNcFNRsnE9Bk4jaU6u4BMLTbn2VKbxJ4iOLWEyjEzwJFMNbX1Nrv6WhJmK7NEoEm79HBJiYHmhCPlaG98P6iMYPQAofshUIY5gQC1ZRYKnm0Yl1qwwcbPkyCBx38upw`
  //   }
  // });
  // return response.data;
  return GET(`order/order-queries/orders/${id}`);

};


export const create = (data : any) => {
  return POST(`order/order-commands/orders/add`,data);
};


export const search = async (data : any) => {  // const response = await axios.put(
  //     `http://localhost:1083/order-commands/orders/${data.id}/edit`, 
  //     data,
  //     {
  //       headers: {
  //         'authorization': `eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcmVzSW4iOjE3NDA4NDU4NzIsImRhdGEiOnsiaWQiOiJiMzQxNWI1Yy00NTNjLTQ4ZTYtODk3YS01MGQ1Y2VmMWI1YzgiLCJlbWFpbCI6ImhpcHNpZ2FAZ21haWwuY29tIiwicHJvZmlsZSI6eyJuaWNrbmFtZSI6ImhpcHNpZ2EiLCJzbHVnIjoiaGlwc2lnYSJ9LCJjb250YWN0Ijp7ImNvbnRhY3RJZCI6ImIzNDE1YjVjLTQ1M2MtNDhlNi04OTdhLTUwZDVjZWYxYjVjOCIsImZpcnN0TmFtZSI6IsSQ4bupYyIsImxhc3ROYW1lIjoiVHLhu41uZyIsInBob25lcyI6W10sImVtYWlscyI6W10sImFkZHJlc3NlcyI6W10sImN1c3RvbUZpZWxkcyI6e319LCJyb2xlcyI6WyJDUyJdLCJwbGFucyI6W119LCJpYXQiOjE3NDAyNDEwNzJ9.jOR_fjOnJHiP5THzVYx5q40hX70UGENhoe7lwgkVi5HkgPtpF5UnUfwEvyim5jwyS1Njy4FD8xsM2xZgYKSBRYOtT1kQehWDwlHK69-zUjZFJtm8nvGJatPRR8qNdMolw3U5diQ2s1I917ci05teiZ_3wv9PWGwvgVSn_BYz8mrYVen8CZzv4uvKTgMwWUYUT9R6h0UgBaRejp1hq29N1sLWNcFNRsnE9Bk4jaU6u4BMLTbn2VKbxJ4iOLWEyjEzwJFMNbX1Nrv6WhJmK7NEoEm79HBJiYHmhCPlaG98P6iMYPQAofshUIY5gQC1ZRYKnm0Yl1qwwcbPkyCBx38upw`
  //       }
  //     }
  // );
  // return response.data;
  // const response = await axios.post(
  //     `http://localhost:1083/order-queries/orders/search`, 
  //     data,
  //     {
  //       headers: {
  //         'authorization': `eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcmVzSW4iOjE3NDA4NDU4NzIsImRhdGEiOnsiaWQiOiJiMzQxNWI1Yy00NTNjLTQ4ZTYtODk3YS01MGQ1Y2VmMWI1YzgiLCJlbWFpbCI6ImhpcHNpZ2FAZ21haWwuY29tIiwicHJvZmlsZSI6eyJuaWNrbmFtZSI6ImhpcHNpZ2EiLCJzbHVnIjoiaGlwc2lnYSJ9LCJjb250YWN0Ijp7ImNvbnRhY3RJZCI6ImIzNDE1YjVjLTQ1M2MtNDhlNi04OTdhLTUwZDVjZWYxYjVjOCIsImZpcnN0TmFtZSI6IsSQ4bupYyIsImxhc3ROYW1lIjoiVHLhu41uZyIsInBob25lcyI6W10sImVtYWlscyI6W10sImFkZHJlc3NlcyI6W10sImN1c3RvbUZpZWxkcyI6e319LCJyb2xlcyI6WyJDUyJdLCJwbGFucyI6W119LCJpYXQiOjE3NDAyNDEwNzJ9.jOR_fjOnJHiP5THzVYx5q40hX70UGENhoe7lwgkVi5HkgPtpF5UnUfwEvyim5jwyS1Njy4FD8xsM2xZgYKSBRYOtT1kQehWDwlHK69-zUjZFJtm8nvGJatPRR8qNdMolw3U5diQ2s1I917ci05teiZ_3wv9PWGwvgVSn_BYz8mrYVen8CZzv4uvKTgMwWUYUT9R6h0UgBaRejp1hq29N1sLWNcFNRsnE9Bk4jaU6u4BMLTbn2VKbxJ4iOLWEyjEzwJFMNbX1Nrv6WhJmK7NEoEm79HBJiYHmhCPlaG98P6iMYPQAofshUIY5gQC1ZRYKnm0Yl1qwwcbPkyCBx38upw`
  //       }
  //     }
  // );
  // return response.data;
  return POST(`order/order-queries/orders/search `,data);
};


export const update = async (data : any) => {
  // const response = await axios.put(
  //     `http://localhost:1083/order-commands/orders/${data.id}/edit`, 
  //     data,
  //     {
  //       headers: {
  //         'authorization': `eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcmVzSW4iOjE3NDA4NDU4NzIsImRhdGEiOnsiaWQiOiJiMzQxNWI1Yy00NTNjLTQ4ZTYtODk3YS01MGQ1Y2VmMWI1YzgiLCJlbWFpbCI6ImhpcHNpZ2FAZ21haWwuY29tIiwicHJvZmlsZSI6eyJuaWNrbmFtZSI6ImhpcHNpZ2EiLCJzbHVnIjoiaGlwc2lnYSJ9LCJjb250YWN0Ijp7ImNvbnRhY3RJZCI6ImIzNDE1YjVjLTQ1M2MtNDhlNi04OTdhLTUwZDVjZWYxYjVjOCIsImZpcnN0TmFtZSI6IsSQ4bupYyIsImxhc3ROYW1lIjoiVHLhu41uZyIsInBob25lcyI6W10sImVtYWlscyI6W10sImFkZHJlc3NlcyI6W10sImN1c3RvbUZpZWxkcyI6e319LCJyb2xlcyI6WyJDUyJdLCJwbGFucyI6W119LCJpYXQiOjE3NDAyNDEwNzJ9.jOR_fjOnJHiP5THzVYx5q40hX70UGENhoe7lwgkVi5HkgPtpF5UnUfwEvyim5jwyS1Njy4FD8xsM2xZgYKSBRYOtT1kQehWDwlHK69-zUjZFJtm8nvGJatPRR8qNdMolw3U5diQ2s1I917ci05teiZ_3wv9PWGwvgVSn_BYz8mrYVen8CZzv4uvKTgMwWUYUT9R6h0UgBaRejp1hq29N1sLWNcFNRsnE9Bk4jaU6u4BMLTbn2VKbxJ4iOLWEyjEzwJFMNbX1Nrv6WhJmK7NEoEm79HBJiYHmhCPlaG98P6iMYPQAofshUIY5gQC1ZRYKnm0Yl1qwwcbPkyCBx38upw`
  //       }
  //     }
  // );
  // return response.data;
  return PUT(`order/order-commands/orders/${data.id}/edit`,data);
};


export const deleteSaleman = (id : string) => {
  return DELETE(`order/order-commands/orders/${id}/remove`);
};

export const packages = async () => {
  // return GET(`order/order-queries/orders/get-packages`);
  const response = await axios.put(`http://localhost:1083/order-queries/orders/get-packages`, {
    headers: {
      'authorization': `eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcmVzSW4iOjE3NDA4NDU4NzIsImRhdGEiOnsiaWQiOiJiMzQxNWI1Yy00NTNjLTQ4ZTYtODk3YS01MGQ1Y2VmMWI1YzgiLCJlbWFpbCI6ImhpcHNpZ2FAZ21haWwuY29tIiwicHJvZmlsZSI6eyJuaWNrbmFtZSI6ImhpcHNpZ2EiLCJzbHVnIjoiaGlwc2lnYSJ9LCJjb250YWN0Ijp7ImNvbnRhY3RJZCI6ImIzNDE1YjVjLTQ1M2MtNDhlNi04OTdhLTUwZDVjZWYxYjVjOCIsImZpcnN0TmFtZSI6IsSQ4bupYyIsImxhc3ROYW1lIjoiVHLhu41uZyIsInBob25lcyI6W10sImVtYWlscyI6W10sImFkZHJlc3NlcyI6W10sImN1c3RvbUZpZWxkcyI6e319LCJyb2xlcyI6WyJDUyJdLCJwbGFucyI6W119LCJpYXQiOjE3NDAyNDEwNzJ9.jOR_fjOnJHiP5THzVYx5q40hX70UGENhoe7lwgkVi5HkgPtpF5UnUfwEvyim5jwyS1Njy4FD8xsM2xZgYKSBRYOtT1kQehWDwlHK69-zUjZFJtm8nvGJatPRR8qNdMolw3U5diQ2s1I917ci05teiZ_3wv9PWGwvgVSn_BYz8mrYVen8CZzv4uvKTgMwWUYUT9R6h0UgBaRejp1hq29N1sLWNcFNRsnE9Bk4jaU6u4BMLTbn2VKbxJ4iOLWEyjEzwJFMNbX1Nrv6WhJmK7NEoEm79HBJiYHmhCPlaG98P6iMYPQAofshUIY5gQC1ZRYKnm0Yl1qwwcbPkyCBx38upw`
    }, 
  });
  return response.data;
};

