/* eslint-disable react-hooks/exhaustive-deps */
import { FButton, FSearch, FSelect, FTable } from '../../../components/element';
import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { DateUtils, StringUtils } from '../../../utils';
import ModalActionOrder from './ModalActionOrder';
import { useOrder } from '../../../middleware/state/order/hooks';
import { useSaleman } from '../../../middleware/state/saleman/hooks';
import {
  convertStatusOrder,
  ORDER_STATUS,
  ROLE,
} from '../../../constant';
import { IPaging } from 'middleware/state/saleman/type';
import { useApp } from '../../../middleware/state/app/hook';



const default_params = {
  limit: 10,
  offset: 1,
}

function OrderPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [params, setParams] = useState<IPaging>(default_params);
  const [action, setAction] = useState('create');
  const [idSaleman, setIdSaleman] = useState('');
  const { onGetList, onSearchOrder, orders, isLoading, total } = useOrder();
  const { onGetListSalesman, salemans } = useSaleman();
  const { role, isAccessRole } = useApp();
  console.log("🚀 ~ file: OrderPage.tsx:33 ~ OrderPage ~ role:", role)
  console.log("🚀 ~ file: OrderPage.tsx:33 ~ OrderPage ~ isAccessRole:", isAccessRole)
  const {id} = useSaleman()
  console.log("🚀 ~ file: OrderPage.tsx:34 ~ OrderPage ~ id:", id)
  const [offsetSearch, setOffSetSearch] = useState(1);
  const [paramsSearch, setParamsSearch] = useState({
    limit: 10,
    offset: 1,
  }) as any;

  const [search, setSearch] = useState() as any;
  const [isSearching,setIsSearching] = useState(false)
  const [columns, setColumns] = useState([
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: 70,
    },
    {
      title: 'Mã đơn hàng',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Voucher',
      dataIndex: 'voucher',
      key: 'voucher',
      render: (voucher: any) => <span>{voucher?.code || '-'}</span>,
    },
    {
      title: 'Giảm',
      dataIndex: 'voucher',
      key: 'voucher',
      render: (voucher: any, row: any) => {
        const voucherValue = row?.voucher_discount_percent ?? voucher?.discountPercentage;
        return (
          <span style={{ color: '#003157', fontWeight: 'bold' }}>
            {voucher ? `${StringUtils.formatPrice(voucherValue)}` : '-'}
          </span>
        )
      },
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'customerPhone',
      key: 'customerPhone',
      render: (customerPhone: any) => <span>{customerPhone || '-'}</span>,
    },
    {
      title: 'Dịch vụ / Sản phẩm',
      dataIndex: 'servicePackage',
      key: 'servicePackage',
      render: (servicePackage: any) => <span>{servicePackage}</span>,
    },
    {
      title: 'Khách hàng',
      dataIndex: 'customerEmail',
      key: 'customerEmail',
    },
    {
      title: 'Giá sản phẩm niêm yết',
      dataIndex: 'listingProductPrice',
      key: 'listingProductPrice',
      render: (listingProductPrice: number) => {
        return (
          <span>{listingProductPrice ? StringUtils.formatPrice(listingProductPrice ?? "0"): '-'}</span>
        )
      }
    },
    {
      title: 'Giá trị thanh toán',
      dataIndex: 'servicePackageAmountTotal',
      key: 'servicePackageAmountTotal',
      render: (servicePackageAmountTotal: number) => (
        <span>{StringUtils.formatPrice(servicePackageAmountTotal ?? "0")}</span>
      ),
    },
    {
      title: 'Phương thức thanh toán',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
    },
    {
      title: 'Ngày tạo đơn',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (createdDate: string) => (
        <span>{DateUtils.formatDate(createdDate)}</span>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => <span>{convertStatusOrder(status)}</span>,
    },
    {
      title: '',
      key: 'action',
      fixed: 'right' as any,
      width: 50,
      render: (_: any, row: any) => (
        <EditOutlined
          onClick={() => {
            setAction('edit');
            setIsModalOpen(true);
            setIdSaleman(row.id);
            console.log("Edit button clicked, row:", row);
          }}
        />
      ),
    },
  ]);

  useEffect(() => {
    if (role.includes(ROLE.CTV_SALES) && !role.includes(ROLE.CS)) {
      const arr = columns.filter((item) => item.key !== 'action');
      setColumns([...arr]);
      console.log("Columns updated based on role:", arr);
    }
  }, [role]);

  useEffect(() => {
    if (!isAccessRole) {
      getOrderByMySelf()
      return;
    }
    onGetList(params);
    setIsSearching(false)
    console.log("Order list fetched with params:", params);
  }, [params]);

  useEffect(() => {
    onGetListSalesman({ offset: 1, limit: 1000 });
    console.log("Salesman list fetched");
  }, []);

  const optionSalemans = useMemo(() => {
    const optionAll = [
      {
        id: '',
        value: '',
        label: 'Tất cả',
      },
    ];
    const optionSaleman = salemans.map((item: any) => {
      return {
        id: item.id,
        label: item.wixUserName,
        value: item.id,
      };
    });

    console.log("Salesman options:", [...optionAll, ...optionSaleman]);
    return [...optionAll, ...optionSaleman];
  }, [salemans]);

  const getOrderByMySelf = () =>{
    handleFilter({
      ...paramsSearch,
      salesmanIds: [id],
      limit: 10,
      offset: 1,
    });
    console.log("Fetching orders for myself with params:", {
      ...paramsSearch,
      salesmanIds: [id],
      limit: 10,
      offset: 1,
    });
  }

  const handleFilter = (req: any) => {
    setSearch(req);
    setParamsSearch(req)
    onSearchOrder(req);
    setIsSearching(true)
    console.log("Filter applied with params:", req);
  };

  const handleCancelFilter = (key?: string,isResetall?:any) => {
    setIsSearching(false)
    if (
      isResetall
    ) {
      setSearch(null);
      onGetList(default_params);
      setParams(default_params);
      setParamsSearch(null);
      console.log("All filters reset");
      return;
    }
    switch (key) {
      case 'q':
        handleFilter({ ...paramsSearch, q: null });
        break;
      case 'statuses':
        handleFilter({ ...paramsSearch,statuses : null });
        break;
      case 'salesmanIds':
        handleFilter({ ...paramsSearch,salesmanIds : null });
        break;
      default:
        break;
    }
    console.log(`Filter ${key} reset`);
  };

  return (
    <div>
      <Row align="middle" justify="space-between" className="mb-2">
        <Col span={12}>
          <Space>
            <FSearch
              textholder="Voucher / Số điện thoại / Email"
              onClick={(e) => {
                if (e) {
                  handleFilter({ ...paramsSearch, q: e, limit: 10, offset: 1 });
                  return;
                }
                handleCancelFilter("q",false);
              }}
            />
            <FSelect
              textholder="Trạng thái"
              options={ORDER_STATUS}
              onChangeValue={(e) => {
                if (e) {
                  handleFilter({
                    ...paramsSearch,
                    statuses: [e],
                    limit: 10,
                    offset: 1,
                  });
                  return;
                }
                handleCancelFilter("statuses",false);
              }}
            />
            {/* {isAccessRole && (
              <FSelect
                textholder="Vui lòng chọn sales"
                options={optionSalemans.filter((item) => item.id !== 0)}
                onChangeValue={(e) => {
                  if (e) {
                    handleFilter({
                      ...paramsSearch,
                      salesmanIds: [e],
                      limit: 10,
                      offset: 1,
                    });

                    return;
                  }
                  handleCancelFilter("salesmanIds",false);
                }}
              />
            )} */}
          </Space>
        </Col>
        {isAccessRole && (
          <Col
            span={12}
            style={{ display: 'none', justifyContent: 'flex-end' }}
          >
            <FButton
              text="Thêm mới"
              onClick={() => {
                setIsModalOpen(true);
                setAction('create');
                console.log("Add new button clicked");
              }}
              size="large"
            />
          </Col>
        )}
      </Row>
      {isModalOpen && (
        <ModalActionOrder
          isModalOpen={isModalOpen}
          handleCancel={() => {
            setIsModalOpen(false);
            setIdSaleman('');
            isSearching ? handleFilter(paramsSearch) :onGetList(params);
            console.log("Modal closed, action:", action);
          }}
          action={action}
          id={idSaleman}
        />
      )}

      <FTable
        columns={columns}
        dataSource={orders}
        className="mt-2"
        loading={isLoading && !isModalOpen}
        total={total}
        current={
          search && Object.keys(search).length !== 0
            ? offsetSearch
            : params.offset
        }
        onChangePage={(e: number) => {
          if (search && Object.keys(search).length !== 0) {
            onSearchOrder({ ...search, offset: e });
            setOffSetSearch(e);
            console.log("Page changed, search params:", { ...search, offset: e });
            return;
          }
          setParams({ ...params, offset: e });
          console.log("Page changed, params:", { ...params, offset: e });
        }}
      />
    </div>
  );
}

export default OrderPage;
